// Font stacks
$font-family-base : 'Inter', 'sans-serif';
$font-family-inter : $font-family-base;
$font-family-jetbrains : 'JetBrains Mono', 'monospace';

// Font weight
$bold: 700;
$semi-bold: 600;
$medium: 500;
$regular: 400;
$light: 300;

$small-screen: 991px;
$xl-screen: 1200px;
$xxl-screen: 1400px;
$tablet-screen: 768px;
