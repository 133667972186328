@import "variables";
@import "typography";


.primary-text{
  color: var(--typography-text-primary);
}

.secondary-text{
  color: var(--typography-text-secondary);
}

.invert-text{
  color: var(--typography-text-invert);
}

.caption-text {
  color: var(--typography-text-caption);
}

.q-blue-p-text{
  color: var(--colors-brand-primary-q-blue-p);
}

.q-purple-s-text{
  color: var(--colors-brand-secondary-q-purple-s);
}

.link-text {
  color: var(--typography-text-link);
}

.btn-cta-hover-text {
  color: var(--buttons-btn-cta-hover-bg);
}

.error-text {
  color: var(--colors-accents-red-a);
}

