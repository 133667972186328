@import "assets/scss/google-font.css";
@import "../node_modules/bootstrap/scss/bootstrap.scss";
@import "../src/app/theme/scss/variables";

/* You can add global styles to this file, and also import other style files */

/* Importing Bootstrap SCSS file. */
@import "bootstrap/scss/bootstrap";

// new theme
@import "app/theme/scss/common-var";

#app-container {
  @import "app/theme/scss/style";
}

.toast-container {
  .success-toast {
    background-color: #50C878;
  }
}

strong.under-img {
  font-weight: 300;
  position: relative;

  &::after {
    content: "";
    position: absolute;
    bottom: 0rem;
    left: 6px;
    right: -0.5rem;
    height: 30%;
    width: 100%;
    opacity: 0.3;
    filter: alpha(opacity=30);

    // Position the line behind the text so that
    // it is still easily readable
    z-index: -1;

    // The SVG is added as an SVG background image
    background-image: url("assets/images/underline.svg");
    background-repeat: no-repeat;

    // This allows the SVG to flex in size to fit
    // any length of word. If the word is short,
    // the SVG will be stretched vertically, if it
    // is long, the SVG will be stretched horizontally.
    // The jagged nature of this particular SVG works
    // with this transforming.
    background-size: contain;
  }
}

.insight-detail-page {
  .ordered-list {
    strong.under-img::after {
      left: -8px;
      background-size: cover;
    }
  }
}

.detail-card,
.img-bg,
.control-index {
  position: relative;

  &::after {
    content: "";
    position: absolute;
    top: 10px;
    left: -10px;
    height: 100%;
    width: 100%;
    z-index: -1;
    background-image: url("assets/images/card-shadow.png");
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 24px;
  }
}

.article-container:hover {
  .article-image-container {
    position: relative;

    &::after {
      content: "";
      position: absolute;
      top: 7px;
      left: -7px;
      height: 100%;
      width: 100%;
      z-index: -1;
      background-color: var(--surface-accent-color12);
      // background-image: url('assets/images/rect_402.png');
      // background-repeat: no-repeat;
      // background-size: cover;
      border-radius: 24px;

      @media screen and (max-width: $small-screen) {
        content: none;
      }
    }
  }
}

.pricing-card {
  &:hover {
    position: relative;

    &::after {
      content: "";
      position: absolute;
      top: 10px;
      left: -10px;
      height: 100%;
      width: 100%;
      z-index: -1;
      background-image: url("assets/images/rect_402.png");
      background-repeat: no-repeat;
      background-size: cover;
      border-radius: 35px;
    }
  }

  @media screen and (max-width: $small-screen) {
    position: relative;

    &::after {
      content: "";
      position: absolute;
      top: 10px;
      left: -10px;
      height: 100%;
      width: 100%;
      z-index: -1;
      background-image: url("assets/images/rect_402.png");
      background-repeat: no-repeat;
      background-size: cover;
      border-radius: 35px;
    }
  }
}

.mobile-card-wrapper {
  .detail-card::after {
    @media screen and (max-width: $small-screen) {
      top: 6px;
      left: -8px;
    }
  }
}

.img-bg {
  &::after {
    top: 8px;
    left: -8px;
    border-radius: 12px;

    @media screen and (max-width: $small-screen) {
      //height: 69%;
      z-index: -1;
      top: 4px;
      left: -4px;
    }
  }
}

.consultancy-page {
  .text-container {
    &::before {
      content: none !important;
    }
  }

  .audit-type {
    .ng-toggle-switch {
      border: 2px solid var(--buttons-btn-cta-hover-bg);
      border-radius: 30px;

      @media screen and (max-width: $small-screen) {
        border-radius: 24px;
      }

      // .ng-toggle-switch-core {
      //   width: 107px !important;
      //   height: 54px !important;
      //   border-radius: 30px !important;
      //   display: flex !important;
      //   align-items: center !important;

      //   @media screen and (max-width: $small-screen) {
      //     width: 71px !important;
      //     height: 36px !important;
      //   border-radius: 24px !important;

      //   }
      // }

      .ng-toggle-switch-label {
        letter-spacing: 0.11px;
        font: $semi-bold 14.25px/18.8px $font-family-inter;
        color: var(--typography-text-primary) !important;

        @media screen and (max-width: $small-screen) {
          letter-spacing: 0.08px;
          font: $semi-bold 9.5px/12.5px $font-family-inter;
        }

        &.ng-toggle-right {
          margin-right: 10px !important;

          @media screen and (max-width: $small-screen) {
            margin-right: 5px !important;
          }
        }

        &.ng-toggle-left {
          margin-left: 10px !important;

          @media screen and (max-width: $small-screen) {
            margin-left: 5px !important;
          }
        }
      }
    }

    .switch-off {
      .ng-toggle-switch {
        border: 2px solid var(--typography-text-input-disabled);
      }
    }
  }

  .control-index {
    &::after {
      top: 6px;
      left: -6px;
      border-radius: 25px;

      @media screen and (max-width: $small-screen) {
        top: 4px;
        left: -4px;
        border-radius: 12.5px;
      }
    }
  }
}

.modal-backdrop {
  background-color: #fff;
  opacity: 0;
}

.form-modal {
  backdrop-filter: blur(5px);

  .modal-dialog {
    @media screen and (max-width: 576px) {
      margin: 0 20px;
    }
  }

  .modal-content {
    border-radius: 32px;
  }
}

.nav-modal-wrapper {
  backdrop-filter: blur(5px);

  .modal-dialog {
    //margin: auto;
    @media screen and (max-width: 576px) {
      margin: 33px 20px;
    }
  }

  .modal-content {
    border-radius: 36px;
  }
}

.home {
  .partner-carousel {
    .carousel-indicators [data-bs-target] {
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background-color: var(--surface-accent-color1);
      border: 1px solid transparent;
    }

    .carousel-indicators {
      margin-bottom: 0;
      bottom: -68px;
      align-items: center;

      .active {
        border: 1px solid var(--typography-text-primary);
        background-color: #ffda61;
      }
    }
  }
}

.pricing-page {
  .thinktime-tooltip {
    opacity: 1;

    .tooltip-inner {
      color: var(--typography-text-invert);
      background-color: var(--buttons-btn-text-cta-sm);
    }

    &.bs-tooltip-end .tooltip-arrow::before {
      border-right-color: var(--buttons-btn-text-cta-sm);
    }

    &.bs-tooltip-start .tooltip-arrow::before {
      border-left-color: var(--buttons-btn-text-cta-sm);
    }

    &.bs-tooltip-top .tooltip-arrow::before {
      border-top-color: var(--buttons-btn-text-cta-sm);
    }

    &.bs-tooltip-bottom .tooltip-arrow::before {
      border-bottom-color: var(--buttons-btn-text-cta-sm);
    }
  }
}

.progress {
  border-radius: 5px;
  background-color: transparent;

  .progress-bar {
    border-radius: 5px;
    background-color: #ffda61;
  }
}

.about-page {
  .control-index {
    &::after {
      top: 6px;
      left: -6px;

      @media screen and (max-width: $small-screen) {
        top: 4px;
        left: -4px;
        border-radius: 10px;
      }
    }
  }
}


.nav-modal {
  display: flex;
  flex-direction: column;
  gap: 32px;
  padding: 31px 42px 54px;
  border: 2px solid var(--typography-text-primary);
  border-radius: 36px;

  .close-btn {
    right: -15px;
    top: -15px;
    background-color: #FFDA61;
    border: 2px solid var(--typography-text-primary);
    padding: 10px;

    &:active {
      background-color: #FFDA61;
      border: 2px solid var(--typography-text-primary);
    }

    .close-icon {
      width: 25px;
      height: 25px;
    }
  }

  .router-link-wrapper {
    gap: 26px;

    @media screen and (max-width: $xl-screen) and (min-width: $small-screen) {
      gap: 18px;
    }

    @media screen and (max-width: $small-screen) {
      gap: 33px;
      width: fit-content;
    }

    .router-link {
      position: relative;
      width: fit-content;

      @media screen and (max-width: $xl-screen) and (min-width: $small-screen) {
        font-size: 14px !important;
      }

      &.active {
        color: var(--typography-text-primary) !important;
        background-color: var(--buttons-btn-primary-hover-bg);
        //width: fit-content;
        clip-path: polygon(6% 0, 100% 0, 100% 100%, 0 100%);
      }

      &::after {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
        background-color: var(--buttons-btn-primary-hover-bg);
        transform: scaleX(0);
        transition: transform 0.3s ease;
        transform-origin: left;
        z-index: -1;
        clip-path: polygon(6% 0, 100% 0, 100% 100%, 0 100%);
      }

      &:hover::after {
        transform: scaleX(1);
      }
    }
  }

  .qeleo-logo {
    outline: none;
    cursor: pointer;
    width: 165px;
    height: 85px;
  }

  .contact-btn {
    padding: 12px 26px;
    background-color: var(--buttons-btn-cta-hover-bg);
    border: 2px solid var(--typography-text-primary);
    color: var(--typography-text-invert);
    margin-top: 17px;

    &:hover {
      color: var(--typography-text-primary);
      outline: 10px solid #FFDA61;
      background-color: var(--surface-bg-white);
    }

    @media screen and (max-width: $xl-screen) and (min-width: $small-screen) {
      font-size: 14px !important;
    }

    @media screen and (max-width: $small-screen) {
      &:hover {
        color: var(--typography-text-invert);
        outline: none;
        background-color: var(--typography-text-primary);
      }
    }
  }
}
