:root {
  --colors-accents-blue-p-a: #8EEFFD;
  --colors-accents-green-a: #C2FFE9;
  --colors-accents-green-l-a: #D0F581;
  --colors-accents-orange-a: #F0864B;
  --colors-accents-pink-a: #FE6BB2;
  --colors-accents-q-blue-a: #D4E1F9;
  --colors-accents-red-a: #FF6B62;
  --colors-accents-yellow-a: #FFFDC2;
  --colors-brand-dark-theme-purple: #9F88FC;
  --colors-brand-light-theme-purple: #745ECC;
  --colors-brand-primary-q-blue-p: #3BA1DF;
  --colors-brand-primary-q-pink-p: #C228DA;
  --colors-brand-primary-q-purple-p: #6C0BCC;
  --colors-brand-primary-q-red-p: #F9235F;
  --colors-brand-secondary-q-emarald-s: #3BA2B5;
  --colors-brand-secondary-q-pink-s: #FF00DD;
  --colors-brand-secondary-q-purple-s: #6B50D8;
  --colors-brand-secondary-q-yellow-s: #FFB324;
  --colors-dark-background-bg-01: #080A17;
  --colors-dark-background-bg-02: #121318;
  --colors-dark-background-bg-03: #1D1F35;
  --colors-dark-background-bg-04: #252A57;
  --colors-dark-background-bg-05: #162A23;
  --colors-dark-background-bg-06: #111325;
  --colors-dark-background-bg-07: #37394E;
  --colors-dark-background-bg-08: #173469;
  --colors-dark-border-border-01: #1E202C;
  --colors-dark-border-border-02: #323547;
  --colors-dark-text-gray-00: #F6F6F6;
  --colors-dark-text-gray-01: #C5C5D2;
  --colors-dark-text-gray-02: #949AA0;
  --colors-dark-text-gray-03: #676767;
  --colors-dark-text-gray-04: #4F4F4F;
  --colors-dark-text-gray-05: #121212;
  --colors-light-background-bg-01: #FFFFFF;
  --colors-light-background-bg-02: #F4F4F4;
  --colors-light-background-bg-03: #ECECEC;
  --colors-light-background-bg-04: #E9E9E9;
  --colors-light-background-bg-05: #E2E2E2;
  --colors-light-background-bg-06: #E8EFFC;
  --colors-light-background-bg-07: #E1F0E9;
  --colors-light-background-bg-08: #F5F6FF;
  --colors-light-border-border-01: #E9E6E6;
  --colors-light-text-gray-00: #3A3361;
  --colors-light-text-gray-01: #4F526D;
  --colors-light-text-gray-02: #868686;
  --colors-light-text-gray-03: #9B9B9B;
  --colors-light-text-gray-04: #AFAFAF;
  --colors-light-text-gray-05: #F6F6F6;
  --padding-md:16px;
}
