@import "variables";

body,
.body-1,
.body-2,
.body-3 {
  font-family: $font-family-base;
  font-weight: $regular;
}

//---- updated typos

.link-lg-underline {
  font-family: $font-family-inter;
  font-size: 16px;
  font-style: normal;
  font-weight: $regular;
  line-height: 160%; /* 25.6px */
  letter-spacing: 1.25px;
  text-decoration-line: underline;
  text-transform: uppercase;
}

.link-sm-underline {
  font-family: $font-family-inter;
  font-size: 14px;
  font-style: normal;
  font-weight: $regular;
  line-height: 160%; /* 22.4px */
  letter-spacing: 1px;
  text-decoration-line: underline;
  text-transform: uppercase
}

.link-xs-underline {
  font-family: $font-family-inter;
  font-size: 12px;
  font-style: normal;
  font-weight: $regular;
  line-height: 160%; /* 19.2px */
  letter-spacing: 0.8px;
  text-decoration-line: underline;
  text-transform: uppercase;
}

.link-lg {
  font-family: $font-family-inter;
  font-size: 16px;
  font-style: normal;
  font-weight: $regular;
  line-height: 160%; /* 25.6px */
  letter-spacing: 0.2px;
}

.link-sm {
  font-family: $font-family-inter;
  font-size: 14px;
  font-style: normal;
  font-weight: $regular;
  line-height: 160%; /* 22.4px */
  letter-spacing: 0.5px;
}

.link-xs {
  font-family: $font-family-inter;
  font-size: 12px;
  font-style: normal;
  font-weight: $regular;
  line-height: 160%; /* 19.2px */
  letter-spacing: 0.25px;
}

.h1-lg {
  font-family: $font-family-jetbrains;
  font-size: 47px;
  font-style: normal;
  font-weight: $bold;
  line-height: 56px; /* 119.149% */
  letter-spacing: -0.25px;
  text-transform: capitalize;
}

@mixin h2-lg {
  font-family: $font-family-jetbrains;
  font-size: 33px;
  font-style: normal;
  font-weight: $medium;
  line-height: 40px; /* 121.212% */
  letter-spacing: 0.25px;
}

.h2-lg {
  @include h2-lg;
}

@mixin h3-lg {
  font-family: $font-family-jetbrains;
  font-size: 23px;
  font-style: normal;
  font-weight: $bold;
  line-height: 28px; /* 121.739% */
}

.h3-lg {
  @include h3-lg;
}

@mixin h4-lg {
  font-family: $font-family-jetbrains;
  font-size: 19px;
  font-style: normal;
  font-weight: $medium;
  line-height: 25px; /* 131.579% */
  letter-spacing: 0.15px;
}

.h4-lg {
  @include h4-lg;
}

@mixin h5-lg {
  font-family: $font-family-jetbrains;
  font-size: 16px;
  font-style: normal;
  font-weight: $medium;
  line-height: 21px; /* 131.25% */
  letter-spacing: 0.15px;
}

.h5-lg {
  @include h5-lg;
}

.h1-sm {
  font-family: $font-family-jetbrains;
  font-size: 33px;
  font-style: normal;
  font-weight: $bold;
  line-height: 40px; /* 121.212% */
  letter-spacing: 0.25px;
}

@mixin h2-sm {
  font-family: $font-family-jetbrains;
  font-size: 23px;
  font-style: normal;
  font-weight: $medium;
  line-height: 28px; /* 121.739% */
}

.h2-sm {
  @include h2-sm;
}

.h3-sm {
  font-family: $font-family-jetbrains;
  font-size: 19px;
  font-style: normal;
  font-weight: $bold;
  line-height: 24px; /* 126.316% */
  letter-spacing: 0.15px;
}

.h4-sm {
  font-family: $font-family-jetbrains;
  font-size: 16px;
  font-style: normal;
  font-weight: $bold;
  line-height: 21px; /* 131.25% */
  letter-spacing: 0.15px;
}

@mixin h5-sm {
  font-family: $font-family-jetbrains;
  font-size: 14px;
  font-style: normal;
  font-weight: $bold;
  line-height: 18px; /* 128.571% */
  letter-spacing: 0.1px;
}

.h5-sm {
  @include h5-sm;
}

.body-1-lg-bold {
  font-family: $font-family-inter;
  font-size: 23px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%; /* 34.5px */
  letter-spacing: 0.7px;
}

@mixin body-1-lg {
  font-family: $font-family-inter;
  font-size: 23px;
  font-style: normal;
  font-weight: $regular;
  line-height: 150%; /* 34.5px */
  letter-spacing: 0.7px;
}

.body-1-lg {
  @include body-1-lg;
}

.body-1-sm {
  font-family: $font-family-inter;
  font-size: 19px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%; /* 28.5px */
  letter-spacing: 0.6px;
}

@mixin body-2-lg {
  font-family: $font-family-inter;
  font-size: 19px;
  font-style: normal;
  font-weight: $regular;
  line-height: 150%; /* 28.5px */
  letter-spacing: 0.6px;
}

.body-2-lg {
  @include body-2-lg;
}

.body-2-sm {
  font-family: $font-family-inter;
  font-size: 16px;
  font-style: normal;
  font-weight: $bold;
  line-height: 160%; /* 25.6px */
  letter-spacing: 0.5px;
}

@mixin body-3-lg {
  font-family: $font-family-inter;
  font-size: 16px;
  font-style: normal;
  font-weight: $regular;
  line-height: 160%; /* 25.6px */
  letter-spacing: 0.5px;
}

.body-3-lg {
  @include body-3-lg;
}

.body-3-sm {
  font-family: $font-family-inter;
  font-size: 14px;
  font-style: normal;
  font-weight: $bold;
  line-height: 160%; /* 22.4px */
  letter-spacing: 0.4px;
}

@mixin body-4-lg {
  font-family: $font-family-inter;
  font-size: 14px;
  font-style: normal;
  font-weight: $regular;
  line-height: 160%; /* 22.4px */
  letter-spacing: 0.4px;
}

.body-4-lg {
  @include body-4-lg;
}

.body-4-sm {
  font-family: $font-family-inter;
  font-size: 12px;
  font-style: normal;
  font-weight: $regular;
  line-height: 160%; /* 19.2px */
  letter-spacing: 0.4px;
}

.body-4-sm-bold {
  font-family: $font-family-inter;
  font-size: 12px;
  font-style: normal;
  font-weight: $bold;
  line-height: 160%; /* 19.2px */
  letter-spacing: 0.4px;
}

@mixin btn-lg {
  font-family: $font-family-inter;
  font-size: 16px;
  font-style: normal;
  font-weight: $medium;
  line-height: normal;
  letter-spacing: 1.25px;
  text-transform: uppercase;
}

.btn-lg {
  @include btn-lg;
}

@mixin btn-sm {
  font-family: $font-family-inter;
  font-size: 14px;
  font-style: normal;
  font-weight: $medium;
  line-height: normal;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.btn-sm {
  @include btn-sm;
}

.btn-xs {
  font-family: $font-family-inter;
  font-size: 12px;
  font-style: normal;
  font-weight: $medium;
  line-height: normal;
  letter-spacing: 0.8px;
  text-transform: uppercase;
}

.code-block-lg {
  font-family: $font-family-jetbrains;
  font-size: 16px;
  font-style: normal;
  font-weight: $medium;
  line-height: 160%; /* 25.6px */
  letter-spacing: 0.4px;
}

.code-inline-lg {
  font-family: $font-family-jetbrains;
  font-size: 14px;
  font-style: normal;
  font-weight: $regular;
  line-height: 160%; /* 22.4px */
  letter-spacing: 0.4px;
}

@mixin code-inline-sm {
  font-family: $font-family-jetbrains;
  font-size: 11px;
  font-style: normal;
  font-weight: $regular;
  line-height: 160%; /* 17.6px */
  letter-spacing: 0.4px;
}

.code-inline-sm {
  @include code-inline-sm;
}

@mixin code-block-sm {
  font-family: $font-family-jetbrains;
  font-size: 12px;
  font-style: normal;
  font-weight: $medium;
  line-height: 160%; /* 19.2px */
  letter-spacing: 0.4px;
}

.code-block-sm {
  @include code-block-sm;
}

.comment-lg {
  font-family: $font-family-jetbrains;
  font-size: 16px;
  font-style: italic;
  font-weight: $light;
  line-height: 160%; /* 25.6px */
  letter-spacing: 0.4px;
}

.comment-sm {
  font-family: $font-family-jetbrains;
  font-size: 12px;
  font-style: italic;
  font-weight: $light;
  line-height: 160%; /* 19.2px */
  letter-spacing: 0.4px;
}

@mixin ui-lg {
  font-family: $font-family-jetbrains;
  font-size: 16px;
  font-style: normal;
  font-weight: $medium;
  line-height: 160%; /* 25.6px */
  letter-spacing: 0.5px;
}

.ui-lg {
 @include ui-lg;
}

.ui-sm {
  font-family: $font-family-jetbrains;
  font-size: 14px;
  font-style: normal;
  font-weight: $medium;
  line-height: 160%; /* 22.4px */
  letter-spacing: 0.4px;
}

.ui-xs {
  font-family: $font-family-jetbrains;
  font-size: 12px;
  font-style: normal;
  font-weight: $medium;
  line-height: 160%; /* 19.2px */
  letter-spacing: 0.4px;
}

.ui-lg-regular {
  font-family: $font-family-jetbrains;
  font-size: 16px;
  font-style: normal;
  font-weight: $regular;
  line-height: 160%; /* 25.6px */
  letter-spacing: 0.5px;
}

.ui-sm-regular {
  font-family: $font-family-jetbrains;
  font-size: 14px;
  font-style: normal;
  font-weight: $regular;
  line-height: 160%; /* 22.4px */
  letter-spacing: 0.4px;
}

.ui-xs-regular {
  font-family: $font-family-jetbrains;
  font-size: 12px;
  font-style: normal;
  font-weight: $regular;
  line-height: 160%; /* 19.2px */
  letter-spacing: 0.4px;
}

.ui-h1 {
  font-family: $font-family-inter;
  font-size: 33px;
  font-style: normal;
  font-weight: 600;
  line-height: 40px; /* 121.212% */
  letter-spacing: 0.25px;
}

.ui-h2 {
  font-family: $font-family-inter;
  font-size: 23px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px; /* 121.739% */
}

.ui-h3 {
  font-family: $font-family-inter;
  font-size: 19px;
  font-style: normal;
  font-weight: $bold;
  line-height: 24px; /* 126.316% */
  letter-spacing: 0.15px;
}

.ui-h4 {
  font-family: $font-family-inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 21px; /* 131.25% */
  letter-spacing: 0.15px;
}

.ui-h5 {
  font-family: $font-family-inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 21px; /* 150% */
  letter-spacing: 0.15px;
}

.caption-lower {
  font-family: $font-family-inter;
  font-size: 12px;
  font-style: normal;
  font-weight: $light;
  line-height: 160%; /* 19.2px */
  letter-spacing: 0.4px;
}

.caption-upper {
  font-family: $font-family-inter;
  font-size: 12px;
  font-style: normal;
  font-weight: $light;
  line-height: 160%; /* 19.2px */
  letter-spacing: 0.4px;
  text-transform: uppercase;
}

.caption-xs {
  font-family: $font-family-inter;
  font-size: 8px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%; /* 11.2px */
  letter-spacing: 0.4px;
}

@mixin caption-sm {
  font-family: $font-family-inter;
  font-size: 10px;
  font-style: normal;
  font-weight: $regular;
  line-height: 140%; /* 14px */
  letter-spacing: 0.4px;
}

.caption-sm {
  @include caption-sm;
}

.caption-lg {
  font-family: $font-family-inter;
  font-size: 15px;
  font-style: normal;
  font-weight: $regular;
  line-height: 140%; /* 14px */
  letter-spacing: 0.4px;
}

@mixin display-lg {
  font-family: $font-family-jetbrains;
  font-size: 93px;
  font-style: normal;
  font-weight: $light;
  line-height: 120%; /* 111.6px */
  letter-spacing: -1.5px;
  text-transform: capitalize;
}

.display-lg {
  @include display-lg;
}

.display-sm {
  font-family: $font-family-jetbrains;
  font-size: 58px;
  font-style: normal;
  font-weight: $medium;
  line-height: 70px; /* 120.69% */
  letter-spacing: -0.5px;
  text-transform: capitalize;
}

.display-xs {
  font-family: $font-family-jetbrains;
  font-size: 47px;
  font-style: normal;
  font-weight: $medium;
  line-height: 56px; /* 119.149% */
  letter-spacing: -0.25px;
  text-transform: capitalize;
}

.subtitle-1-lg {
  font-family: $font-family-inter;
  font-size: 23px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px; /* 121.739% */
}

.subtitle-1-sm {
  font-family: $font-family-inter;
  font-size: 19px;
  font-style: normal;
  font-weight: 600;
  line-height: 25px; /* 131.579% */
  letter-spacing: 0.15px;
}

.subtitle-2-lg {
  font-family: $font-family-inter;
  font-size: 19px;
  font-style: normal;
  font-weight: 500;
  line-height: 25px; /* 131.579% */
  letter-spacing: 0.15px;
}

.subtitle-2-sm {
  font-family: $font-family-inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 21px; /* 131.25% */
  letter-spacing: 0.15px;
}

.subtitle-3-lg {
  font-family: $font-family-inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 21px; /* 131.25% */
  letter-spacing: 0.15px;
}

.subtitle-3-sm {
  font-family: $font-family-inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px; /* 112.5% */
  letter-spacing: 0.15px;
}

.data-lg {
  font-family: $font-family-inter;
  font-size: 33px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px; /* 84.848% */
}

.data-sm {
  font-family: $font-family-inter;
  font-size: 23px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px; /* 86.957% */
}

.overline-lg {
  font-family: $font-family-jetbrains;
  font-size: 23px;
  font-style: normal;
  font-weight: 500;
  line-height: 112px; /* 486.957% */
  letter-spacing: -1.5px;
  text-transform: uppercase;
}

.overline-sm {
  font-family: $font-family-jetbrains;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px; /* 233.333% */
  letter-spacing: 0.25px;
  text-transform: uppercase;
}

.font-type-16px:not(.constituent-time) {
  font-size: 16px !important;
}

.font-type-18px:not(.constituent-time) {
  font-size: 18px !important;
}

.font-type-20px:not(.constituent-time) {
  font-size: 20px !important;
}

.typo-secondary {
  color: var(--typography-text-secondary, #C5C5D2);
}

.typo-caption {
  color: var(--typography-text-caption);
}

.font-italic {
  font-style: italic;
}

